var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Unmemoized version of {@link useRefToCallback}
 * @see {@link useRefToCallback}
 * @param ref
 */

function refToCallback(ref) {
  return function (newValue) {
    if (typeof ref === "function") {
      ref(newValue);
    } else if (ref) {
      ref.current = newValue;
    }
  };
}

exports.refToCallback = refToCallback;

var nullCallback = function () {
  return null;
}; // lets maintain a weak ref to, well, ref :)
// not using `kashe` to keep this package small


var weakMem = new WeakMap();

var weakMemoize = function (ref) {
  var usedRef = ref || nullCallback;

  if (weakMem.has(usedRef)) {
    return weakMem.get(usedRef);
  }

  var cb = refToCallback(usedRef);
  weakMem.set(usedRef, cb);
  return cb;
};
/**
 * Transforms a given `ref` into `callback`.
 *
 * To transform `callback` into ref use {@link useCallbackRef|useCallbackRef(undefined, callback)}
 *
 * @param {ReactRef} ref
 * @returns {Function}
 *
 * @see https://github.com/theKashey/use-callback-ref#reftocallback
 *
 * @example
 * const ref = useRef(0);
 * const setRef = useRefToCallback(ref);
 * 👉 setRef(10);
 * ✅ ref.current === 10
 */


function useRefToCallback(ref) {
  return weakMemoize(ref);
}

exports.useRefToCallback = useRefToCallback;
export default exports;