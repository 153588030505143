var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * creates a Ref object with on change callback
 * @param callback
 * @returns {RefObject}
 *
 * @see {@link useCallbackRef}
 * @see https://reactjs.org/docs/refs-and-the-dom.html#creating-refs
 */

function createCallbackRef(callback) {
  var current = null;
  return {
    get current() {
      return current;
    },

    set current(value) {
      var last = current;

      if (last !== value) {
        current = value;
        callback(value, last);
      }
    }

  };
}

exports.createCallbackRef = createCallbackRef;
export default exports;