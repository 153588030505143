import _assignRef from "./assignRef";
import _createRef from "./createRef";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var assignRef_1 = _assignRef;
var createRef_1 = _createRef;

function transformRef(ref, transformer) {
  return createRef_1.createCallbackRef(function (value) {
    return assignRef_1.assignRef(ref, transformer(value));
  });
}

exports.transformRef = transformRef;
export default exports;